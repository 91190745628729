import {useTranslation}                             from "react-i18next";
import {useAuth}                                    from "@/services/Auth";
import {useParams}                                  from "react-router-dom";
import React, {useEffect, useState}                 from "react";
import LoadingPage                                  from "@/components/LoadingPage";
import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {ErrorMessage, Field, Formik, Form}          from "formik";
import * as Yup                                     from "yup";
import Select                                       from "react-select";
import FleetForm                                    from "./FleetForm";
import SuccessDialog                                from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function FleetEdit(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const putRequest = auth.putRequest;
    let {id} = useParams();


    const [fleet, setFleet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatingFleet, setUpdatingFleet] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);

    const getVehicles = () => {
        getRequest('/vehicles')
            .then(response => {
                setVehicles(response.data.vehicles);
            })
            .catch(error => {
            })
    }

    useEffect(() => {
        getVehicles();
        getRequest('/fleets/' + id, { include: ['spending_caps', 'account_items', 'vehicles', 'company'] })
            .then(response => {
                setFleet(response.data.fleet);
                //client data
                setCompany({
                    value: response.data.fleet.company_id,
                    label: response.data.fleet.client.name
                });
                setCompanies(response.data.companies);

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })

    }, []);

    const handleSubmit = (payload) => {
        setUpdatingFleet(true);
        putRequest(`/fleets/${fleet.id}`, payload)
            .then(response => {
                setUpdatingFleet(false);
                if (response.data.message && response.data.message === "OK") {
                    setUpdateSuccess(true);
                }
            })
            .catch(error => {
                setUpdatingFleet(false);

                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
    };


    const handleClose = () => {
        setUpdateSuccess(false)
        if (id !== '' && id != null) {
            props.history.push(`/fleets/${id}`)
        }
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('edit')} / {t('drawer_link_titles.fleets')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={updateSuccess}
                    message="success_dialog.fleet_updated"
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{fleet.name}</h4>
                </Card.Subtitle>

                <FleetForm
                    fleet={fleet}
                    loading={updatingFleet}
                    buttonText="save_new_fleet"
                    fleetCompany={company}
                    fleetCompanies={companies}
                    handleSubmit={handleSubmit}
                    serverErrors={serverErrors}
                    initialVehicles={fleet.vehicles}
                    availableVehicles={vehicles.map(vehicle => {
                        return {value: vehicle.id, label: vehicle.registration_number}
                    })}
                />
            </Card>
        )
    )
}

export default FleetEdit;
