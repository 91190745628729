import {useTranslation}             from "react-i18next";
import {useAuth}                    from "@/services/Auth";
import React, {useEffect, useState} from "react";
import {Link}                       from "react-router-dom";
import ToolkitProvider, {Search}    from "react-bootstrap-table2-toolkit";
import LoadingPage                  from "@/components/LoadingPage";
import {Card, Col, Form, Row}       from "react-bootstrap";
import BootstrapTable               from "react-bootstrap-table-next";
import paginationFactory            from "react-bootstrap-table2-paginator";
import filterFactory                from "react-bootstrap-table2-filter";


import Select      from 'react-select';
import DatePicker  from "react-datepicker";
import useMoment from "@/hooks/useMoment";

function SiteManagerDashboard() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const postRequest = auth.postRequest;

    const {momentLocal} = useMoment()

    const [loading, setLoading] = useState(true);
    const [loadStats, setLoadStats] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [site, setSite] = useState('');
    const [siteRevenue, setSiteRevenue] = useState(0);
    const [totalMPakFee, setTotalMParkFee] = useState(0);
    const [totalNonParkingRevenue, setTotalNonParkingRevenue] = useState(0);
    const [averageOrderValue, setAverageOrderValue] = useState(0);

    const [sites, setSites] = useState([]);
    const [order_items, setOrderItems] = useState([]);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [dateRangeChanged, setDateRangeChanged] = useState(false);

    const getDashFilterData = () => {
        setLoading(true);
        postRequest('/dashboard/site-manager', {
            data: 'filter'
        })
            .then(response => {
                if (response.data.message === 'OK') {
                    setSites(response.data.sites);
                    setLoading(false);
                    setInitialLoad(false);
                }
            })
            .catch(error => {

            })
    }

    const getDasStatsData = (start, end) => {
        setLoadStats(true);
        postRequest('/dashboard/site-manager', {
            data     : 'stats',
            site_id  : site ? site.value : null,
            from_date: start ? start : startDate,
            to_date  : end ? end : endDate,
        })
            .then(response => {
                if (response.data.message === 'OK') {
                    setSiteRevenue(response.data.total_revenue);
                    setTotalMParkFee(response.data.total_m_park_fee);
                    setTotalNonParkingRevenue(response.data.total_non_parking_revenue);
                    setAverageOrderValue(response.data.average_order_value);
                    setOrderItems(response.data.order_items);
                    setLoading(false);
                    setLoadStats(false);
                }
            })
            .catch(error => {
                setLoading(false);
                setLoadStats(false);
            })
    }

    useEffect(() => {
        if (initialLoad) {
            getDashFilterData();
        }
        getDasStatsData(startDate, endDate);
    }, [site, dateRangeChanged]);

    const {SearchBar} = Search;

    const columns = [
        {
            dataField: 'id',
            text     : t('order_item_id'),
        },
        {
            dataField: 'order.order_number',
            text     : t('order_id'),
            formatter: (cell, row) => row.order?.id && <Link to={`/orders/${row.order?.id}`}>{cell}</Link> || cell,
        },
        {
            dataField: 'order.booking.booking_number',
            text     : t('booking_id'),
        },
        {
            dataField: 'order.booking.user.name',
            text     : t('name'),
        },
        {
            dataField: 'order.created_at',
            text     : t('date'),
            formatter: date => date && momentLocal(date).format(t('formats.date'))
        },
        !auth.user.is_operator &&
        {
            dataField: 'total_amount',
            text     : t('amount'),
            align    : 'right',
            formatter: (total_amount, row) => <span>{total_amount && total_amount.toLocaleString(undefined, {minimumFractionDigits: 2})} {row.order?.site?.currency}</span>
        },
        {
            dataField: 'order.booking.from_datetime',
            text     : t('from'),
            formatter: date => date && momentLocal(date).format(t('formats.date'))
        },
        {
            dataField: 'order.booking.to_datetime',
            text     : t('to'),
            formatter: date => date && momentLocal(date).format(t('formats.date'))
        },
    ];


    const paginationOptions = {
        paginationSize: 4,
    };


    const updateDate = (date, type) => {
        if (type === 'START') {
            setStartDate(date);
            if (date && endDate) {
                getDasStatsData(date, endDate);
            }
        } else if (type === 'END') {
            setEndDate(date);
            if (startDate && date) {
                getDasStatsData(startDate, date);
            }
        }
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <div className="site-find-card position-relative">
                {
                    loadStats &&
                    <LoadingPage/>
                }

                {/*(auth.user.is_supplier_admin || auth.user.is_site_manager|| auth.user.is_operator) &&*/}
                {
                    auth.user.is_supplier_admin &&
                    <h3 className="text-primary">{t('dashboard.supplier_dashboard')}</h3>
                }
                {
                    auth.user.is_site_manager ?
                    <h3 className="text-primary">{t('dashboard.site_manager_dashboard')}</h3>
                                              :
                    auth.user.is_operator ?
                    <h3 className="text-primary">{t('dashboard.operator_dashboard')}</h3> : null
                }
                {
                    auth.user.is_supplier_operator &&
                    <h3 className="text-primary">{t('dashboard.supplier_operator_dashboard')}</h3>
                }
                <Row>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Select
                            className="react-select"
                            isClearable={true}
                            placeholder={t('site')}
                            value={site}
                            onChange={(e) => setSite(e)}
                            options={sites.map(site => {
                                return {value: site.id, label: site.name}
                            })}/>
                    </Col>

                    <Col sm={6} className="col-12 mt-2">
                        <Row className="my-md-0 my-2">
                            <Col className="col-12 d-flex align-items-center">
                                <Form.Control
                                    placeholder={t('from')}
                                    type="date"
                                    max={endDate ? momentLocal(endDate).format('YYYY-MM-DD') : null}
                                    onChange={(e) => updateDate(e.target.value, 'START')}
                                />
                                <span className="mx-2">{t('to')}</span>
                                <Form.Control
                                    placeholder={t('from')}
                                    type="date"
                                    min={startDate ? momentLocal(startDate).format('YYYY-MM-DD') : null}
                                    onChange={(e) => updateDate(e.target.value, 'END')}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

              { !auth.user.is_operator && <Row className="mt-2">
                <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                  <Card bg="primary" className="mb-2 dash-card" text="light">
                    <Card.Body>
                      <Card.Title className="text-start">
                        <h3>{siteRevenue}</h3>
                      </Card.Title>
                      <Card.Text className="text-start">
                        {t('dashboard.total_revenue')}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                  <Card bg="primary" className="mb-2 dash-card" text="light">
                    <Card.Body>
                      <Card.Title className="text-start">
                        <h3>{totalNonParkingRevenue}</h3>
                      </Card.Title>
                      <Card.Text className="text-start">
                        {t('dashboard.total_non_parking_revenue')}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                  <Card bg="primary" className="mb-2 dash-card" text="light">
                    <Card.Body>
                      <Card.Title className="text-start">
                        <h3>{averageOrderValue}</h3>
                      </Card.Title>
                      <Card.Text className="text-start">
                        {t('dashboard.average_order_value')}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                  <Card bg="primary" className="mb-2 dash-card" text="light">
                    <Card.Body>
                      <Card.Title className="text-start">
                        <h3>{totalMPakFee}</h3>
                      </Card.Title>
                      <Card.Text className="text-start">
                        {t('dashboard.total_m_park_fee')}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>}

                <div className="mt-2">
                    <ToolkitProvider
                        keyField="id"
                        data={order_items}
                        columns={columns}
                        search
                        exportCSV={{
                            fileName: 'm-park-orders.csv',
                        }}
                    >
                        {
                            props => (

                                <BootstrapTable
                                    striped
                                    responsive
                                    hover
                                    {...props.baseProps}
                                    pagination={paginationFactory(paginationOptions)}
                                    filter={filterFactory()}
                                />
                            )
                        }
                    </ToolkitProvider>
                </div>
            </div>
        )
    )
}

export default SiteManagerDashboard;
